import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchResetPassword } from '@/store/main/actions';
let UserProfileEdit = class UserProfileEdit extends Vue {
    constructor() {
        super(...arguments);
        this.appName = appName;
        this.valid = true;
        this.password1 = '';
        this.password2 = '';
    }
    mounted() {
        this.checkToken();
    }
    reset() {
        this.password1 = '';
        this.password2 = '';
        this.$validator.reset();
    }
    cancel() {
        this.$router.push('/');
    }
    checkToken() {
        const token = this.$router.currentRoute.query.token;
        if (!token) {
            commitAddNotification(this.$store, {
                content: 'No token provided in the URL, start a new password recovery',
                color: 'error',
            });
            this.$router.push('/recover-password');
        }
        else {
            return token;
        }
    }
    async submit() {
        if (await this.$validator.validateAll()) {
            const token = this.checkToken();
            if (token) {
                // @ts-ignore
                await dispatchResetPassword(this.$store, {
                    token,
                    password: this.password1,
                });
                this.$router.push('/');
            }
        }
    }
};
UserProfileEdit = __decorate([
    Component
], UserProfileEdit);
export default UserProfileEdit;
